(function ($) {
  Drupal.behaviors.filterableShadesGridsFormatterV1 = {
    isMobile: false,
    unisonDependent: true,
    unisonReady: false,
    attached: false,
    selector: {
      defaultFilter: '.js-filterable-shades-grid-item-prefilter',
      container: '.js-filterable-find-shades-block',
      grid: '.js-filterable-shades-grids-formatter',
      item: '.js-filterable-shades-grid-item',
      filtered: 'js-filterable-shades__grid--filtered',
      unfiltered: 'js-filterable-shades__grid--unfiltered',
      ctaContainer: '.js-filterable-shades-grid-item--cta',
      itemLink: '.js-filterable-shades-grid-item__link',
      itemATB: '.js-filterable-shades-grid-item--add-to-bag',
      itemInvStatus: '.js-filterable-shades-grid-item__inventory-status',
      itemPrice: '.js-filterable-shades-grid-item--price',
      viewMoreBtn: '.js-filterable-shades-grids-view-more',
      resultCardOverlay: '.js-filterable-shades-grids-formatter-result',
      stickyFooterCopy:  '.js-filterable-find-shades-footer-bottom',
    },

    cssClass: {
      filtered: 'filterable-shades-grids--filtered',
      unfiltered: 'filterable-shades-grids--unfiltered',
      columnPartial: 'grid-columns--'
    },

    settings: {
      layout: 'masonry',
      itemsPerRowUnfiltered: 7,
      itemsPerRowUnfilteredMobile: 3,
      itemsPerRowFiltered: 5,
      itemsPerRowFilteredMobile: 2,
      itemsViewMoreNumRows: 3,
      itemDefault: null,
      defaultFilter: 'all'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      if (!!Unison.fetch.now()) {
        self.isMobile = self.checkIfMobile();
        self.unisonReady = true;
      }
      Unison.on('change', function () {
        if (!self.attached && self.unisonDependent) {
          // Re attach on unisonReady, the initial attach was return
          self.attach(context);
        }
      });
      if (!self.unisonReady && self.unisonDependent) {
        return;
      }
      self.attached = true;
      self.setup(context);
    },

    switchView: function ($that, viewData) {
      if (viewData.view === 'showResults') {
        $that.fadeTo(100, 0, function () {
          $that.addClass('hidden');
          $that.nodes.$container.trigger('filterable_products:initResults', [
            { sku: viewData.sku }
          ]);
        });
        $that.nodes.$container.data('currentView', 'results');
      }
      if (viewData.view === 'showGrid') {
        $that.removeClass('hidden');
        $that.fadeTo(100, 1, function () {
          $that.nodes.$container.trigger('filterable_products:destroyResults');
        });
        $that.nodes.$container.data('currentView', 'grid');
      }
    },
    displayPrdOverlay: function($this, $that) {
      var self = this;
      var $shadeItemSelected = $this.closest('.js-filterable-shades-grid-item');
      var itemActive = $shadeItemSelected.hasClass('shade-selected');
      var dataskuBaseId = $shadeItemSelected.data('skuBaseId');
      var spaceDefault = 100;

      if (!itemActive) {
        $that.nodes.$items.removeClass('shade-selected');
        $shadeItemSelected.addClass('shade-selected');
        $that.nodes.$container.trigger('filterable_shades:selectSku', dataskuBaseId);
        if (!$that.nodes.$resultCardOverlay.hasClass('result-displayed')) {
          $that.nodes.$resultCardOverlay.addClass('result-displayed');
          if ($that.settings.isOnOverlay) {
            $that.nodes.$shadeOverlay.addClass('find-shade-filter-result-displayed');
          }
        }
        if (!self.isMobile) {
          $('html, body').animate({
            scrollTop: $that.nodes.$container.offset().top - spaceDefault
          }, 100);
        }
      }
    },
    viewMoreGrids:  function ($that) {
      var viewMoreDisabled = $that.nodes.$viewMoreBtn.hasClass('viewmore-disabled');
        if (!viewMoreDisabled) {
          $that.nodes.$items.removeClass('items-filtered-hidden');
          $that.nodes.$viewMoreBtn.addClass('viewmore-disabled');
        }
    },
    setEvents: function ($that) {
      var self = this;

      $that.nodes.$viewMoreBtn.once().on('click', function() {
        self.viewMoreGrids($that);
      }).on('keydown', function (event) {
        if (event.key === "Enter") {
          self.viewMoreGrids($that);
        }
      });

      $that.nodes.$itemLink.once().on('click keydown', function(e) {
        e.preventDefault();
        var $this = $(this);
        if (e.key === 'Enter' || e.type === 'click') {
          self.displayPrdOverlay($this, $that);
        }
      });

      $that.on('stateChanged', function (e, onDefault) {
        self.setState($that, onDefault);
      });

      $that.nodes.$container
        .on('filterable_products:newItems', function () {
          self.getDom($that);
          $that.addClass('new-items-processed');
        })
        .on('filterable_products:switchView', function (e, eventData) {
          self.switchView($that, eventData);
        });
      $(document).on('initFindShadeGridOverlay', '.js-filterable-shades-grids-formatter', function () {
        $that.nodes.$container.trigger('filterable_shades:close_prd_card_results');
        self.initGrid($that);
      });
      $that.nodes.$container.off('filterable_products:allShadeResult').on('filterable_products:allShadeResult', function () {
        self.updateFilterableResult($that);
      });

      $that.nodes.$shadeOverlay.off('scroll.stiskyFooterFindYouShade').on('scroll.stiskyFooterFindYouShade', _.throttle(updateStickyFooterCopy, 300));

      function updateStickyFooterCopy () {
        if (!$that.settings.isOnOverlay || !self.isMobile) {
          return;
        }
        var $closeBtnOverlay = $('.js-product-find-shade-overlay-close', $that.nodes.$shadeOverlay);
        var scrollPos = $that.nodes.$shadeOverlay.scrollTop();
        var posGrids = $that.offset().top;
        var showSticky = scrollPos === 0 ? true : false;
        var isCloseBtnOverGrids = $closeBtnOverlay.position().top > posGrids ? true: false;
        $closeBtnOverlay.toggleClass('close-over-grids', isCloseBtnOverGrids);
        $that.nodes.$shadeOverlay.toggleClass('sticky-footer-bottom', showSticky);
      };

      updateStickyFooterCopy();

    },
    updateFilterableResult: function ($that) {
      var self = this;
      var formName = $that.data('filterTypeFindShade') === 'filter_prd_foundation' ? 'spp_foundation_finder' : null;
      var saveResult = formName ? true : false;
      var $shadeSelected = $that.nodes.$items.filter('.shade-selected');

      if ($shadeSelected.length <= 0 || !saveResult) {
        return;
      }

      self.saveResult($shadeSelected, formName);
      $(document).trigger('recommendation_update:quiz');
    },

    saveResult: function ($shadeSelected, formName) {
      var shadeSkuBaseId = $shadeSelected.data('skuBaseId');
      var prodId = $shadeSelected.data('productId');
      var query_string = 'show_results=1&skuId=' + shadeSkuBaseId;
      var itemKey = 'spp-quiz:' + formName;
      var fullString = localStorage.getItem(itemKey);
      var fullQueryString;
      var tempObjFullQuery = {};
      var newFullQueryString = "";
      var encodeBase64 = function (str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
              return String.fromCharCode('0x' + p1);
          }
        ));
      };
      var decodeBase64 = function(b64Text) {
        return !!b64Text ? decodeURIComponent(atob(b64Text)): '';
      };

      fullQueryString = !!fullString ? decodeBase64(fullString) : null;
      tempObjFullQuery = !!fullQueryString ? JSON.parse(fullQueryString) : {};
      tempObjFullQuery[prodId] = query_string;
      newFullQueryString = JSON.stringify(tempObjFullQuery);
      newFullQueryString = encodeBase64(newFullQueryString);
      // Save back to localStorage.
      localStorage.setItem(itemKey, newFullQueryString);
    },

    initGrid: function ($that, isReinit) {
      var self = this;

      if ($that.hasClass('mixitup-initialized')) {
        return;
      }

      $that.nodes.$items.hide();
      var mixItUpArgs = {
        animation: {
          duration: 500,
          effects: 'fade',
          easing: 'ease-in-out',
          clampHeight: false
        },
        selectors: {
          target: self.selector.item
        },
        layout: {
          display: 'flex'
        },
        load: {
          filter: $that.settings.defaultFilter
        }
      };

      if (isReinit) {
        $that.addClass('no-animation');
      }
      $that.on('mixEnd', function (e, state) {
        var $itemsActiveFiltered = state.$show;
        if (!$that.settings.isOnOverlay) {
          if ($that.settings.itemDefault >= state.totalShow) {
            $that.nodes.$viewMoreBtn.addClass('viewmore-disabled');
          } else if ($that.nodes.$viewMoreBtn.hasClass('viewmore-disabled')) {
            $itemsActiveFiltered.slice($that.settings.itemDefault).addClass('items-filtered-hidden');
            $that.nodes.$viewMoreBtn.removeClass('viewmore-disabled');
          }
        }
        $that.addClass('mixitup-initialized');
      });
      $that.mixItUp(mixItUpArgs);
    },

    getSettings: function ($that) {
      var self = this;

      $that.settings = {};
      $that.settings.layout = $that.data('gridLayout') || self.settings.layout;
      $that.settings.itemsPerRowUnfiltered =
        $that.data('unfilteredColumns') || self.settings.itemsPerRowUnfiltered;
      $that.settings.itemsPerRowUnfilteredMobile =
        $that.data('unfilteredColumnsMobile') || self.settings.itemsPerRowUnfilteredMobile;
      $that.settings.itemsPerRowFiltered =
        $that.data('filteredColumns') || self.settings.itemsPerRowFiltered;
      $that.settings.itemsPerRowFilteredMobile =
        $that.data('filteredColumnsMobile') || self.settings.itemsPerRowFilteredMobile;
      $that.settings.itemsViewMoreNumRows =
        $that.data('viewmoreRows') || self.settings.itemsViewMoreNumRows;
      $that.settings.defaultFilter = $that.data('defaultFilterValue') === self.settings.defaultFilter 
                  ? self.settings.defaultFilter
                  : '.filterable-shades-grid__item--filter-' + $that.data('defaultFilterValue');
      $that.settings.isOnOverlay = $that.nodes.$shadeOverlay.length > 0 ? 1 : 0;

      if(!!$that.data('showViewMore') && !$that.settings.isOnOverlay) {
        $that.settings.itemDefault = $that.settings.itemsViewMoreNumRows * $that.settings.itemsPerRowFiltered || 12;
        if (self.isMobile) {
          $that.settings.itemDefault = $that.settings.itemsViewMoreNumRows * $that.settings.itemsPerRowFilteredMobile || 12;
        }
        $that.attr('data-item-default', $that.settings.itemDefault);
      }
    },

    setup: function (context) {
      var self = this;
      var $grids = $(self.selector.grid, context);

      Unison.on('change', function () {
        self.isMobile = self.checkIfMobile();
        $grids.trigger('stateChanged');
      });

      $grids.each(function () {
        var $this = $(this);

        self.getDom($this);
        self.getSettings($this);
        self.setState($this);
        self.setEvents($this);
        if (!$this.settings.isOnOverlay) {
          self.initGrid($this);
        }
      });
    },

    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$container = $that.closest(self.selector.container);
      $that.nodes.$items = $(self.selector.item, $that);
      $that.nodes.$itemImages = $that.nodes.$items.find('img');
      $that.nodes.$controlls = $();
      $that.nodes.$slides = $();
      $that.nodes.$dots = $();
      $that.nodes.$dot = $();
      $that.nodes.$ctaContainer = $(self.selector.ctaContainer, $that);
      $that.nodes.$itemLink = $(self.selector.itemLink, $that);
      $that.nodes.$itemATB = $(self.selector.itemATB, $that);
      $that.nodes.$itemInvStatus = $(self.selector.itemInvStatus, $that);
      $that.nodes.$itemPrice = $(self.selector.itemPrice, $that);
      $that.nodes.$viewMoreBtn = $(self.selector.viewMoreBtn, $that.nodes.$container);
      $that.nodes.$resultCardOverlay = $(self.selector.resultCardOverlay, $that.nodes.$container);
      $that.nodes.$shadeOverlay = $that.closest('.js-product-find-shade-overlay-v1');
      $that.nodes.$stickyFooterCopy = $(self.selector.stickyFooterCopy, $that.nodes.$container);
    },

    checkIfMobile: function () {
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();

      if (!!bp && !!bps) {
        return parseInt(bp.width, 10) <= parseInt(bps.medium, 10);
      }

      return false;
    },

    setState: function ($that, onDefault) {
      var self = this;
      self.resetItemsPerRow($that);
      if ($that.hasClass(self.cssClass.filtered) && !onDefault) {
        self.resetFilteredTemplate($that);
      } else {
        self.setUnfilteredTemplate($that);
      }
    },

    setUnfilteredTemplate: function ($that) {
      // Initial grid layout
      var self = this;
      var itemsPerRowKey =
        self.isMobile ? 'itemsPerRowUnfilteredMobile' : 'itemsPerRowUnfiltered';
      var itemsPerRow = $that.settings[itemsPerRowKey];

      $that.nodes.$items.addClass(self.cssClass.columnPartial + itemsPerRow.toString());
      self.updateHeightGridContainer($that);
    },

    resetFilteredTemplate: function ($that) {
      // Reset grid after filter
      var self = this;
      var itemsPerRowKey =
        self.isMobile ? 'itemsPerRowFilteredMobile' : 'itemsPerRowFiltered';
      var itemsPerRow = $that.settings[itemsPerRowKey];

      $that.nodes.$items.addClass(self.cssClass.columnPartial + itemsPerRow.toString());
      self.updateHeightGridContainer($that);
    },

    updateHeightGridContainer: function ($that) {
      var gridHeight = $that.nodes.$items.first().height();
      var gapGrid = 2;
      var gridContainerHeight = gridHeight * $that.settings.itemsViewMoreNumRows - gapGrid;

      if ($that.settings.isOnOverlay) {
        return;
      }
      $that.css('min-height', gridContainerHeight);
    },

    resetItemsPerRow: function ($grid) {
      var self = this;

      self.removeClass($grid.nodes.$items, self.cssClass.columnPartial);
    },

    removeClass: function ($nodes, classString) {
      return $nodes.removeClass(function (index, classes) {
        var classesArray = classes.split(' ');
        var removeClass = _.filter(classesArray, function (className) {
            return className.indexOf(classString) === 0;
          }).toString();

        return removeClass;
      });
    }
  };
})(jQuery);
